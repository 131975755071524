<template>
  <v-dialog
    v-model="isVisible"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Share Sender Details</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
            >
              <v-autocomplete
                v-model="field.selectedUser"
                label="Search users"
                :items="formattedUsers"
                clearable
                filled
                rounded
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="submit"
        >
          Share
        </v-btn>
      </v-card-actions>
      <v-snackbar
        v-model="snackbar"
      >
        {{ notification }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'

  export default {
    name: 'ShareSenderDialog',
    props: {
      users: {
        type: Array,
        default: () => {},
      },
      isVisible: {
        type: Boolean,
        default: false,
      },
      selectedSender: {
        type: Object,
        default: null,
      },
    },
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      username: null,
      snackbar: false,
      field: {
        selectedUser: null,
      },
      formattedUsers: [],
      isFormDirty: false,
    }),

    watch: {
      isVisible (val) {
        if (val) {
          // Format users for autocomplete
          this.formattedUsers = this.users.map(u => u.username)
        }
      },
    },

    created () {
      this.username = localStorage.getItem('username')
    },

    methods: {

      reset () {
        this.field.selectedUser = ''
      },

      close () {
        this.reset()
        this.$emit('on-close')
      },

      isFormValid () {
        this.notification = ''
        if (!this.field.selectedUser || !this.field.selectedUser.length) {
          this.notification = 'Please select a user first'
          return false
        }
        return true
      },

      submit () {
        this.isFormDirty = true
        if (!this.isFormValid()) {
          this.snackbar = true
          return
        }
        // Valid
        // Trigger api call
        this.$emit('on-submit')
        this.httpConnector.makeRequest('post', `${this.apiEndpoint}grantsenderaccess/${this.selectedSender}/${this.field.selectedUser}?code=${this.apiCode}`)
          .then((res) => {
            this.$emit('on-success', res)
            this.reset()
            this.close()
          })
          .catch((error) => {
            this.$emit('on-error', error)
          })
      },
    },
  }
</script>
